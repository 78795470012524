<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      :style="'border-bottom: 5px solid ' + this.customerColor + ' !important;'"
      flat
      dark
      clipped-left
    >
      <v-app-bar-nav-icon @click="drawer()"></v-app-bar-nav-icon>
      <router-link to="/">
        <v-img
          alt="Loxi Logo"
          aspect-ratio="1"
          class="shrink mr-2 pa-0"
          contain
          src="@/assets/Loxi logo_3_white.svg"
          transition="scale-transition"
          height="30"
      /></router-link>
      <!-- <span class="ml-4">{{ CustomerTitle }}</span> -->
      <span class="ml-4">{{ $appConfig.customer.title }}</span>
      <v-spacer></v-spacer>

      <v-text-field
        v-model="globalSearch"
        outlined
        dense
        hide-details
        clearable
        placeholder="Global Search"
        append-icon="mdi-magnify"
        @keyup.enter="$router.push('/GlobalSearch/' + globalSearch)"
        @blur="globalSearch = ''"
      >
      </v-text-field>

      <img
        :alt="this.$appConfig.customer.prefix"
        aspect-ratio="1"
        class="shrink mx-10"
        contain
        id="imgCustomer"
        :src="`logos/${this.$appConfig.customer.logo}`"
        transition="scale-transition"
        height="35"
        @load="imgLoaded()"
      />
      <span v-if="user" class="mr-5"> {{ user.fullname }} </span>
      <v-btn v-if="!isAuthenticated" icon :to="{ name: 'Login' }">
        <v-icon> mdi-account </v-icon></v-btn
      >
      <div v-if="isAuthenticated">
        <v-menu bottom left offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              v-if="isAuthenticated"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-avatar
                size="40"
                :color="user.color"
                :class="
                  applyDark(user.color)
                    ? 'white--text subtitle-1'
                    : 'black--text subtitle-1'
                "
                ><img
                  v-if="gravatarURL"
                  :src="gravatarURL"
                  :alt="user.fullname"
                />
                <span v-else>{{
                  user.fullname
                    .match(/(\b\S)?/g)
                    .join('')
                    .match(/(^\S|\S$)?/g)
                    .join('')
                    .toUpperCase()
                }}</span></v-avatar
              ></v-btn
            >
          </template>
          <v-list dense>
            <v-list-item @click="$router.push('/Profile')">
              <v-list-item-title> {{ $t('menu.profile') }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="
                $can('create', 'tokens') &&
                $feature('lucidChartIntegration') &&
                tokens
              "
              :href="LucidChartUrl"
            >
              <v-list-item-title>{{ $t('menu.linkLucid') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="
                $can('create', 'tokens') &&
                $appConfig.externalIssues == 'DevOps' &&
                tokens
              "
              :href="DevOpsUrl"
            >
              <v-list-item-title>{{ $t('menu.linkDevOps') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="
                $can('create', 'tokens') &&
                $appConfig.externalIssues == 'Jira' &&
                tokens
              "
              :href="JiraUrl"
            >
              <v-list-item-title>{{ $t('menu.linkJira') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                $store.dispatch('auth/logout');
                $router.push('/Login');
              "
            >
              <v-list-item-title>{{ $t('menu.logout') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-navigation-drawer
        v-if="user && user.role != 'vendor'"
        v-model="$store.state.drawer"
        absolute
        app
        light
        temporary
        width="500"
        clipped
        hoverable
        class="text-body-2"
        ><v-sheet class="pa-4 primary lighten-2">
          <v-text-field
            v-model="search"
            :label="$t('menu.searchTree')"
            dark
            flat
            solo-inverted
            hide-details
            clearable
            @input="handleSearch"
            clear-icon="mdi-close-circle-outline"
          ></v-text-field>
        </v-sheet>
        <v-treeview
          ref="tree"
          v-if="items"
          :items="items"
          :search="search"
          :filter="filter"
          dense
          hoverable
          @update:active="clickOnNode"
          ><template v-slot:prepend="{ item }">
            <v-icon
              small
              class="ma-1"
              color="primary"
              v-if="item.Variant == true"
            >
              mdi-format-list-text
            </v-icon>
            <v-icon small class="ma-1" color="primary" v-else>
              mdi-sitemap
            </v-icon>
          </template>
          <template v-slot:label="{ item }">
            <span @click="clickOnNode(item)"
              >{{ item.Number }} - {{ item.Name }}</span
            ></template
          ></v-treeview
        >
      </v-navigation-drawer>
    </v-app-bar>

    <v-card>
      <v-navigation-drawer
        app
        permanent
        expand-on-hover
        clipped
        mini-variant
        v-if="isAuthenticated"
      >
        <v-list nav dense>
          <v-list-item
            link
            :style="$route.name == 'Process' ? activeStyle : ''"
            @click="$router.push('/Process')"
            v-if="user.role != 'vendor'"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-format-list-bulleted-square</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('menu.processList') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            :style="$route.name == 'End2End' ? activeStyle : ''"
            link
            @click="$router.push('/end2end')"
            v-if="user.role != 'vendor'"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-file-tree</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('menu.end2endList') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            :style="$route.name == 'ProcessBrowser' ? activeStyle : ''"
            link
            @click="$router.push('/ProcessBrowser')"
            v-if="user.role != 'vendor'"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-book-open-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              $t('menu.processbrowser')
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="$feature('specification') && user.role != 'vendor'"
            :style="$route.name == 'specification' ? activeStyle : ''"
            link
            @click="$router.push('/Specification')"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-pin</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              $t('menu.specifications')
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="!$feature('specification') && user.role != 'vendor'"
            :style="$route.name == 'requirement' ? activeStyle : ''"
            link
            @click="$router.push('/Requirement')"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-pin</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('menu.requirements') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            :style="$route.name == 'Fields' ? activeStyle : ''"
            @click="$router.push('/Fields')"
            v-if="user.role != 'vendor'"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-focus-field</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('menu.fields') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="$feature('testScenarios') && user.role != 'vendor'"
            :style="$route.name == 'TestScenariosOverview' ? activeStyle : ''"
            link
            @click="$router.push('/TestScenariosOverview')"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-test-tube</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              $t('menu.testScenarios')
            }}</v-list-item-title>
          </v-list-item>
          <!-- <v-list-item
            link
            :style="$route.name == 'Comments' ? activeStyle : ''"
            @click="$router.push('/Comments')"
            v-if="user.role != 'vendor'"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-comment-processing-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('menu.comments') }}</v-list-item-title>
          </v-list-item> -->
          <v-list-item
            link
            :style="$route.name == 'Issues' ? activeStyle : ''"
            @click="$router.push('/Issues')"
            v-if="user.role != 'vendor'"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-login-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('menu.issues') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            :style="$route.name == 'planner' ? activeStyle : ''"
            @click="$router.push('/Planner')"
            v-if="$feature('planner') && user.role != 'vendor'"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-calendar-month</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('menu.planner') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            :style="$route.name == 'Dashboard' ? activeStyle : ''"
            @click="$router.push('/Dashboard')"
            v-if="$can('read', 'dashboard')"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-finance</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('menu.dashboard') }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="
              $can('create', 'process') &&
              $can('create', 'variant') &&
              $can('create', 'process-step') &&
              $feature('lucidChartIntegration') &&
              validToken
            "
            link
            :style="$route.name == 'DocumentPicker' ? activeStyle : ''"
            @click="$router.push('/documentpicker')"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-tray-arrow-up</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              $t('menu.processImport')
            }}</v-list-item-title></v-list-item
          >
          <v-list-item
            v-if="
              $can('create', 'process') &&
              $can('create', 'variant') &&
              $can('create', 'process-step') &&
              $can('import', 'process')
            "
            link
            :style="$route.name == 'CopyProcess' ? activeStyle : ''"
            @click="$router.push('/copyProcess')"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-content-copy</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('menu.copyProcess') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="
              $can('create', 'process') &&
              $can('create', 'variant') &&
              $can('create', 'process-step') &&
              $feature('cloudinaryChartIntegration')
            "
            link
            :style="$route.name == 'fileUploader' ? activeStyle : ''"
            @click="$router.push('/fileuploader')"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-tray-arrow-up</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('menu.uploadImages') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            v-if="user.vendor && user.vendorId > 0"
            :style="$route.name == 'VendorResponse' ? activeStyle : ''"
            @click="$router.push('/vendorresponse')"
          >
            <!-- Temporary only allow user ids less than 3-->
            <v-list-item-icon>
              <v-icon color="primary">mdi-forum</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('menu.rfpResponse') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            v-if="$can('manage', 'settings')"
            :style="$route.name == 'Settings' ? activeStyle : ''"
            @click="$router.push('/settings')"
          >
            <!-- Temporary only allow user ids less than 3-->
            <v-list-item-icon>
              <v-icon color="primary">mdi-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('menu.settings') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-card>
    <v-main><router-view /> </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import { makeFindMixin } from 'feathers-vuex';
import { mapActions } from 'vuex';
import ColorThief from 'colorthief';
import md5 from 'blueimp-md5';
import { applyDark } from '@/utils/Utilities';
import axios from 'axios';
import feathersClient from './feathers-client';

export default {
  name: 'App',
  data() {
    return {
      customerColor: null,
      search: null,
      caseSensitive: false,
      globalSearch: null,
    };
  },
  mixins: [
    makeFindMixin({ service: 'process' }),
    makeFindMixin({ service: 'tokens' }),
  ],
  computed: {
    ...mapGetters({
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated',
    }),
    CustomerTitle() {
      if (this.$store?.state?.globalSettings?.length > 0) {
        return this.$store.state.globalSettings.find(
          (f) => f.key == 'CustomerTitle'
        )
          ? this.$store.state.globalSettings.find(
              (f) => f.key == 'CustomerTitle'
            )?.value
          : '';
      } else {
        return '';
      }
    },

    validToken() {
      let lucidToken = this.tokens.find(
        (f) => f.Application == 'LucidChartUser'
      );
      if (lucidToken) {
        let userToken = lucidToken.tokens_users.find(
          (f) => f.TokenUserId == this.user.id
        );
        if (userToken) {
          return true;
        }
      }

      return false;
    },

    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].includes(search)
        : undefined;
    },

    processParams() {
      return {};
    },
    tokensParams() {
      return {};
    },
    activeStyle() {
      return {
        'background-color': '#d1f5ed',
      };
    },
    items() {
      if (this.process.length > 0) {
        return this.process
          .map((i) => {
            return {
              id: i.id,
              processId: i.id,
              Name: i.Name,
              name: i.Name,
              Number: i.Number,
              Variant: false,
              children: i.variants
                ? i.variants
                    .map((i2) => {
                      return {
                        id: i.id + ',' + i2.id,
                        variantId: i2.id,
                        Name: i2.Name,
                        name: i.Number + '.' + i2.Number + ' - ' + i2.Name,
                        Number: i2.Number,
                        Variant: true,
                      };
                    })
                    .sort((a, b) => (a.Number > b.Number ? 1 : -1))
                : null,
            };
          })
          .sort((a, b) => (a.Number > b.Number ? 1 : -1));
      } else return [];
    },
    gravatarURL() {
      let url = null;
      if (this.isAuthenticated) {
        // let hash = CryptoJS.MD5(this.user.email).toString();
        let hash = md5(this.user.email.toLowerCase().trim());
        url = `https://www.gravatar.com/avatar/${hash}.jpg?s=80&d=404`;
        var http = new XMLHttpRequest();
        http.open('HEAD', url, false);
        http.send();
      } else {
        url = null;
      }
      return http.status == 404 ? null : url;
    },
    customerBorderStyle() {
      return '3px solid red !important;'; //`3px solid ${this.$appConfig.customer.color} !important;`,
    },
    LucidChartUrl() {
      if (this.tokens) {
        let lucidToken = this.tokens.find(
          (f) => f.Application == 'LucidChartUser'
        );
        if (lucidToken) {
          return `https://lucid.app/oauth2/authorizeUser?client_id=${lucidToken.ClientId}&redirect_uri=${lucidToken.RedirectUri}&scope=${lucidToken.Scope}&state=${this.user.email}`;
        } else {
          return null;
        }
      } else return null;
    },
    DevOpsUrl() {
      if (this.tokens) {
        let devOpsToken = this.tokens.find((f) => f.Application == 'DevOps');
        if (devOpsToken) {
          let url = `https://app.vssps.visualstudio.com/oauth2/authorize?client_id=${devOpsToken.ClientId}&response_type=Assertion &state=${this.user.email}&scope=${devOpsToken.Scope}&redirect_uri=${devOpsToken.RedirectUri}`;
          return url;
        } else {
          return null;
        }
      }
      return null;
    },
    JiraUrl() {
      if (this.tokens) {
        let jiraToken = this.tokens.find((f) => f.Application == 'JiraAPI');
        if (jiraToken) {
          let url = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${jiraToken.ClientId}&scope=${jiraToken.Scope}&redirect_uri=${jiraToken.RedirectUri}&state=${this.user.email}&response_type=code&prompt=consent`;
          return url;
        } else {
          return null;
        }
      }
      return null;
    },
    //https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=G66SN7nduFyUEUiqVIfiKaxdkyZ5kisY&scope=read%3Ajira-work%20write%3Ajira-work%20offline_access&redirect_uri=https%3A%2F%2Fdev-backend.loxi.be%2Fatlassian&state=lode.bovyn@foocus.be&response_type=code&prompt=consent
  },
  methods: {
    applyDark,
    ...mapActions(['setCurrentProcess', 'setDrawioTemplateUrl', 'setSettings']),
    drawer() {
      this.$store.commit('toggleDrawer');
    },
    handleSearch: function (val) {
      if (val) {
        if (!this.allOpened) {
          this.lastOpen = this.open;
          this.allOpened = true;
          this.$refs.tree.updateAll(true);
        }
      } else {
        this.$refs.tree.updateAll(false);
        this.allOpened = false;
        this.open = this.lastOpen;
      }
    },
    clickOnNode(node) {
      let process = this.process.filter((f) => f.id == node.processId)[0];
      this.setCurrentProcess(process);
      node.Variant
        ? this.$router.push({
            name: 'processStep',
            params: { id: node.variantId },
          })
        : this.$router.push({
            name: 'processDetail',
            params: { id: node.processId, number: node.Number },
          });
    },
    imgLoaded() {
      const colorThief = new ColorThief();
      const img = document.getElementById('imgCustomer');

      const rgbToHex = (r, g, b) =>
        '#' +
        [r, g, b]
          .map((x) => {
            const hex = x.toString(16);
            return hex.length === 1 ? '0' + hex : hex;
          })
          .join('');
      let mainColor = null;
      try {
        mainColor = colorThief.getColor(img);
      } catch (error) {
        mainColor = [0, 0, 0];
      }
      let hexColor = rgbToHex(mainColor[0], mainColor[1], mainColor[2]);
      this.customerColor = hexColor;
    },
    async generateDiagramUrl() {
      const baseURL =
        'https://embed.diagrams.net/?embed=1&configure=1&saveAndExit=1&returnbounds=1&proto=json&spin=1&clibs=U';
      const customTemplateURL = `https://loxi.eu-central-1.linodeobjects.com/DrawIO/${this.$appConfig.customer.prefix}_LoxiTemplate.xml`;
      const fallbackTemplateURL =
        'https://loxi.eu-central-1.linodeobjects.com/DrawIO/LoxiTemplate.xml';

      let URL = null;

      try {
        const response = await axios.head(customTemplateURL);
        // If the custom template exists, use it; otherwise, fall back
        URL =
          baseURL +
          (response.status === 200 ? customTemplateURL : fallbackTemplateURL);
      } catch (e) {
        // On error, fall back to the default URL
        URL = baseURL + fallbackTemplateURL;
      }
      this.setDrawioTemplateUrl(URL);
    },
  },
  watch: {
    async isAuthenticated() {
      if (this.isAuthenticated) {
        // Load settings
        const settings = await feathersClient.service('settings').find();
        if (settings?.total > 0) {
          this.setSettings(settings.data);
        }
      }
    },
  },
  async mounted() {
    await this.generateDiagramUrl();
  },
};
</script>

<style lang="scss">
@import '/node_modules/@syncfusion/ej2-base/styles/material3.css';
@import '/node_modules/@syncfusion/ej2-buttons/styles/material3.css';
@import '/node_modules/@syncfusion/ej2-calendars/styles/material3.css';
@import '/node_modules/@syncfusion/ej2-dropdowns/styles/material3.css';
@import '/node_modules/@syncfusion/ej2-grids/styles/material3.css';
@import '/node_modules/@syncfusion/ej2-inputs/styles/material3.css';
@import '/node_modules/@syncfusion/ej2-lists/styles/material3.css';
@import '/node_modules/@syncfusion/ej2-navigations/styles/material3.css';
@import '/node_modules/@syncfusion/ej2-popups/styles/material3.css';
@import '/node_modules/@syncfusion/ej2-splitbuttons/styles/material3.css';
@import '/node_modules/@syncfusion/ej2-treegrid/styles/material3.css';
@import '/node_modules/@syncfusion/ej2-vue-inputs/styles/material3.css';
@import '/node_modules/@syncfusion/ej2-vue-navigations/styles/material3.css';
@import '/node_modules/@syncfusion/ej2-vue-notifications/styles/material3.css';
@import '/node_modules/@syncfusion/ej2-vue-popups/styles/material3.css';
@import '/node_modules/@syncfusion/ej2-vue-richtexteditor/styles/material3.css';
@import '/node_modules/@syncfusion/ej2-vue-schedule/styles/material3.css';

// body,
// .v-application {
//   font-family: Open Sans;
// }

// .v-application .text-body-2,
// .v-application .caption,
// .v-application .overline {
//   font-family: Open Sans !important;
// }

.toasted.success {
  background-color: #009688 !important;
  font-family: 'Roboto' !important;
  font-weight: bold;
}
.toasted.info {
  background-color: black !important;
  color: white;
  font-family: 'Roboto' !important;
  font-weight: bold;
}

.toasted.error {
  background-color: #f44336 !important;
  font-family: 'Roboto' !important;
  font-weight: bold;
}
.v-card__text,
.v-card__title {
  word-break: normal !important; /* maybe !important  */
}
.buttonlink {
  text-decoration: none;
}
.dot {
  height: 25px;
  width: 25px;

  border-radius: 50%;
  display: inline-block;
}
.activeTab {
  // color: #d1f5ed !important;
  background-color: #d1f5ed;
}

.v-application a {
  color: #008a93 !important;
}

.v-application a:hover {
  color: #005f63 !important;
}

.container {
  max-width: 1350px !important;
}

// overrule syncfusion primary color to white
:root {
  // --color-sf-on-primary: #929292;
  --color-sf-primary: 146, 146, 146;
}

// Remove spacing in paragraphs in rte
.e-richtexteditor .e-rte-content .e-content p,
.e-richtexteditor .e-source-content .e-content p {
  margin-bottom: 5px;
}

.e-richtexteditor .e-rte-content .e-content li,
.e-richtexteditor .e-source-content .e-content li {
  margin-bottom: 0px;
}

ul {
  margin-bottom: 5px !important;
}

// fix rte toolbar always on top
.e-richtexteditor .e-toolbar-wrapper,
.e-richtexteditor .e-toolbar-container {
  z-index: 6;
}

.v-application p {
  margin-bottom: 5px;
}

.e-splitter .e-split-bar.e-split-bar-horizontal {
  z-index: 0;
}
</style>
