<template>
  <div>
    <v-switch
      v-model="showAll"
      label="Show All"
      hide-details
      class="mb-5 ml-4"
    ></v-switch>
    <v-data-table
      v-if="filteredStepsOnSelection"
      :headers="headers"
      :items="filteredStepsOnSelection"
      :items-per-page="20"
      item-key="id"
      class="elevation-1"
      disable-pagination
      :show-select="testScenarioLine.Detailed"
      show-expand
      dense
      v-model="selected"
      @item-selected="clickedRow"
      @toggle-select-all="clickedAll"
      :hide-default-footer="true"
      :loading="isPending"
      loading-text="Loading... Please wait"
      sort-by="Key"
      :sort-desc="false"
    >
      <template v-slot:[`item.Number`]="{ index, item }">
        {{ index + 1 }}
        <v-icon v-if="item.Deleted" color="red" small>mdi-delete-alert</v-icon>
        <v-icon
          v-if="new Date() - new Date(item.createdAt) < 5 * 60 * 1000"
          color="green"
          small
          >mdi-new-box</v-icon
        >
      </template>
      <template v-slot:[`item.color`]="{ item }">
        <span
          v-for="color in group.find((f) => f.id == item.GroupId)?.allGroups"
          :key="color.id"
          ><span v-if="color.Loop">
            <v-tooltip bottom open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="circle pointer"
                  @click="addLoop(item, color)"
                  :style="{
                    background: color.Color,
                    color: applyDark(color.Color) ? 'white' : 'black',
                  }"
                >
                  {{
                    item.Groups.find((f) => f.id == color.id)?.iteration + 1
                  }}</span
                ></template
              >{{ color.Name }}</v-tooltip
            ></span
          ><span v-else>
            <v-tooltip bottom open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="circle"
                  :style="{
                    background: color.Color,
                    color: applyDark(color.Color) ? 'white' : 'black',
                  }"
                >
                  &nbsp;</span
                ></template
              >{{ color.Name }}</v-tooltip
            >
          </span>
        </span>
      </template>
      <template v-slot:[`item.process_step.Name`]="{ item }">
        <span>{{ item.process_step.Name }}</span>
        <v-icon
          small
          class="ml-2"
          @click="deleteLine(item)"
          v-if="hasIterationGreaterThanZero(item.Groups)"
          >mdi-delete</v-icon
        >
      </template>
      <template v-slot:[`item.Fields`]="{ item }">
        <TestScenarioStepFields
          v-if="item.id"
          :testScenarioLineStep="item"
          :disabled="!item.id"
        />
      </template>
      <template v-slot:[`item.TestRemark`]="{ item }">
        <div class="testRemark" @click="clickEditTestRemark(item)">
          <span v-html="truncateHTML(item.TestRemark, 40)" />
          <v-icon
            class="icon-top-right"
            small
            @click="clickEditTestRemark(item)"
            >mdi-pencil</v-icon
          >
        </div>
      </template>
      <template v-slot:[`item.Reference`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <!-- <pre>{{ item.Groups }}</pre>
            {{ item.Key }} -->
            <v-icon
              v-bind="attrs"
              v-on="on"
              :color="item.ReferenceLineId ? 'green' : ''"
              @click="clickAddReference(item)"
              >mdi-vector-point-plus</v-icon
            >
          </template>
          {{
            item.ReferenceLineId
              ? lineReferences.find((f) => f.id == item.ReferenceLineId)?.value
              : 'No Output Linked'
          }}
        </v-tooltip>
      </template>
      <template v-slot:[`item.process_step.status.Testable`]="{ item }">
        <v-icon v-if="item.process_step?.status?.Testable" color="green"
          >mdi-check</v-icon
        >
        <v-icon v-else color="red">mdi-close</v-icon>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length" v-if="item?.process_step?.Description">
          <v-row
            ><v-col class="mb-0 pb-0"
              ><span
                class="ma-3 ql-editor"
                v-html="item?.process_step?.Description"
              ></span></v-col
          ></v-row>
        </td>
      </template>
    </v-data-table>
    <Modal
      title="Add Output Reference"
      v-if="ShowAddReference"
      @cancel="ShowAddReference = false"
      @confirm="saveLineStep(currentItem)"
    >
      <v-select
        v-model="currentItem.ReferenceLineId"
        clearable
        :items="lineReferences"
        item-text="value"
        item-value="id"
        label="Output Reference"
        outlined
        dense
        hide-details
      ></v-select>
    </Modal>
    <Modal
      title="Edit test Remark"
      width="1100px"
      v-if="showTestRemarkEditor"
      @cancel="showTestRemarkEditor = false"
      @confirm="
        saveLineStep(currentItem);
        showTestRemarkEditor = false;
      "
    >
      <RichEditor
        v-model="currentItem.TestRemark"
        :disabled="!$can('update', currentItem, 'TestRemark')"
      ></RichEditor>
    </Modal>
  </div>
</template>

<script>
import TestScenarioStepFields from './TestScenarioStepFields.vue';
import RichEditor from '@/components/general/RichEditor.vue';
import Modal from '@/components/general/Modal.vue';
import { makeFindMixin } from 'feathers-vuex';
import { truncateHTML, applyDark } from '@/utils/Utilities';
import { handleErrorResponse } from '@/utils/MessageHandler';
import feathersClient from '@/feathers-client';

export default {
  props: {
    variant: {
      type: Object,
      required: true,
    },
    stepsToAdd: {
      type: Array,
      required: false,
    },
    testScenarioLine: {
      type: Object,
      required: true,
    },
    testLines: {
      type: Array,
      required: true,
    },
  },
  components: { TestScenarioStepFields, RichEditor, Modal },
  mixins: [
    makeFindMixin({
      service: 'test-scenario-line-step',
      watch: ['reload'],
    }),
    makeFindMixin({
      service: 'process-step',
      watch: ['variant.id'],
    }),
    makeFindMixin({
      service: 'group',
      watch: ['variant.id'],
    }),
  ],
  watch: {
    isPending: async function () {
      if (this.isPending == false) {
        this.selected = this.testScenarioLineStep.filter((f) => f.Selected);
        await this.mergeUpdatedSteps();
      }
    },
    saving: function () {
      this.$emit('saving', this.saving);
    },
  },

  data() {
    return {
      showAll: true,
      ShowAddReference: false,
      showTestRemarkEditor: false,
      currentItem: null,
      reload: 0,
      selected: [],
      saving: false,
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'color',
          width: '100px',
        },
        {
          text: 'Number',
          align: 'center',
          sortable: false,
          value: 'Number',
          width: '50px',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'process_step.Name',
        },
        {
          text: 'Fields',
          align: 'start',
          sortable: false,
          value: 'Fields',
          width: '250px',
        },
        {
          text: 'Test Remark',
          align: 'start',
          sortable: false,
          value: 'TestRemark',
        },
        {
          text: 'Reference',
          align: 'start',
          sortable: false,
          value: 'Reference',
        },
        {
          text: 'Testable',
          align: 'center',
          sortable: false,
          value: 'process_step.status.Testable',
        },
        {
          text: '',
          align: 'end',
          value: 'data-table-expand',
          sortable: false,
          groupable: false,
        },
      ],
    };
  },
  methods: {
    truncateHTML,
    applyDark,
    hasIterationGreaterThanZero(data) {
      return data?.some((obj) => obj.iteration > 0);
    },
    async deleteLine(item) {
      this.saving = true;
      await item.remove();
      this.saving = false;
    },
    async addLoop(lineToCopy, color) {
      this.saving = true;
      // Find all lines from the same group with the same iteration for all groups
      // const lines = this.testScenarioLineStep.filter(
      //   (step) => step.Groups.some((group) => group.id === lineToCopy.GroupId)
      //   // &&
      //   // step.Groups.every(
      //   //   (group) =>
      //   //     group.iteration ===
      //   //     line.Groups.find((g) => g.id === group.id)?.iteration
      //   // )
      // );

      const indexToMatch = lineToCopy.Groups.findIndex((f) => f.id == color.id);

      let arrayToMatch = [];

      if (indexToMatch !== -1) {
        // Cut off the array at the found index
        arrayToMatch = lineToCopy.Groups.slice(0, indexToMatch + 1);
        console.log(arrayToMatch); // Output: [1, 2, 3, 4]
      } else {
        console.log('Element not found in the array');
      }

      const lines = this.testScenarioLineStep.filter((step) => {
        const groupsToMatch = step.Groups.slice(0, indexToMatch + 1);
        return (
          groupsToMatch.length === arrayToMatch.length &&
          groupsToMatch.every((group, index) => {
            if (index === indexToMatch) {
              return (
                group.id === arrayToMatch[index].id &&
                group.iteration === lineToCopy.Groups[indexToMatch].iteration
              );
            }
            return (
              group.id === arrayToMatch[index].id &&
              group.iteration === arrayToMatch[index].iteration
            );
          })
        );
      });

      console.log('Lines that will be copied', lines);
      console.log('color clicked', color);

      // Create new lines based on the existing ones
      for (const line of lines) {
        const newLine = { ...line };
        const group = newLine.Groups[indexToMatch];
        if (group) {
          group.iteration += 1; // Increment the iteration by 1
        }

        // Recalculate the counter
        let orderCounter = 0;
        for (const group of newLine.Groups) {
          orderCounter +=
            Math.pow(1000, group.exp) * group.firstStepNumber +
            (group.iteration * Math.pow(1000, group.exp)) / 100;
        }
        newLine.Key = orderCounter;
        delete newLine.id;
        delete newLine.createdAt;
        delete newLine.updatedAt;
        delete newLine.ModifiedById;
        delete newLine.CreatedById;

        try {
          await feathersClient
            .service('test-scenario-line-step')
            .create(newLine);
        } catch (error) {
          console.log(error);
        }
      }
      this.saving = false;
      this.reload += 1;
    },
    clickEditTestRemark(item) {
      if (item.id) {
        this.currentItem = item;
        this.showTestRemarkEditor = true;
      }
    },
    clickAddReference(item) {
      if (item.id) {
        this.currentItem = item;
        this.ShowAddReference = true;
      }
    },
    async saveLineStep(item) {
      this.saving = true;
      try {
        await item.save();
      } catch (error) {
        handleErrorResponse(error);
      }
      this.saving = false;
      this.ShowAddReference = false;
    },
    async clickedRow(row) {
      this.saving = true;
      row.item.Selected = row.value;
      try {
        await row.item.save();
      } catch (error) {
        handleErrorResponse(error);
      }
      this.saving = false;
    },
    async clickedAll(rows) {
      this.saving = true;
      for (const row of rows.items) {
        row.Selected = rows.value;
        try {
          await row.save();
        } catch (error) {
          handleErrorResponse(error);
        }
      }
      this.saving = false;
    },
    async mergeUpdatedSteps() {
      this.saving = true;

      const steps = this.processStep;
      const testlineSteps = this.testScenarioLineStep;

      const newSteps = steps.filter(
        (step) => !testlineSteps.some((line) => line.ProcessStepId === step.id)
      );

      // Add new steps
      if (newSteps?.length > 0) {
        await this.addNewStep(newSteps);
        await this.reorder();
        // this.reload += 1;
        this.showAll = !this.showAll;
        this.showAll = !this.showAll;
      }

      const removedSteps = testlineSteps.filter(
        (line) => !steps.some((step) => step.id === line.ProcessStepId)
      );

      // Remove steps
      if (removedSteps?.length > 0) {
        for (const step of removedSteps) {
          try {
            step.Deleted = true;
            step.Selected = false;
            step.save();
          } catch (error) {
            console.log(error);
          }
        }
        // await this.reorder();
        // this.reload += 1;
      }

      // TODO Add new Groups on existing steps?

      // TODO Add Steps for all iterations?

      await this.reorder();

      this.saving = false;
    },

    async addNewStep(newSteps) {
      const { TestScenarioLineStep } = this.$FeathersVuex.api;

      if (newSteps.length > 0) {
        for (const [index, step] of newSteps.entries()) {
          let newStep = new TestScenarioLineStep();
          newStep.Number = index + 1;
          newStep.ProcessStepId = step.id;
          newStep.TestScenarioLineId = this.testScenarioLine.id;
          newStep.GroupId = step.GroupId;
          newStep.Selected = false;

          newStep.process_step = step;
          newStep.Number = step.Number;
          // newStep.Key = orderCounter;

          // newStep.Groups = fullGroups;

          try {
            await newStep.save();
          } catch (error) {
            handleErrorResponse(error);
          }
        }
      }
    },
    async reorder() {
      const maxLevel =
        this.group.filter((f) => f.VariantId == this.variant.id)?.length > 0
          ? Math.max(
              ...this.group
                .filter((f) => f.VariantId == this.variant.id)
                .map((g) => g.allGroups.length)
            )
          : 0;

      // Group by GroupId and get the first Number per group
      const firstSteps = {};
      this.processStep
        .sort((a, b) => a.Number - b.Number)
        .forEach((step) => {
          if (!firstSteps[step.GroupId]) {
            firstSteps[step.GroupId] = step.Number;
          }
        });

      if (this.processStep?.length > 0) {
        for (const step of this.testScenarioLineStep) {
          const group = this.group.find((g) => g.id === step.GroupId);

          let fullGroups = [
            {
              id: 0,
              level: 0,
              firstStepNumber: 1, //step.process_step.Number,
              exp: maxLevel + 1,
              iteration: 0,
            },
          ];
          for (let groupIndex = 0; groupIndex < maxLevel; groupIndex++) {
            const newGroupId = group?.allGroups[groupIndex]
              ? group?.allGroups[groupIndex].id
              : 0;
            const nextGroupId = group?.allGroups[groupIndex + 1]
              ? group?.allGroups[groupIndex + 1].id
              : 0;
            const firstStep =
              firstSteps[newGroupId] || firstSteps[nextGroupId] || 1; // step.process_step.Number;

            console.log(step.process_step.Name, newGroupId, firstSteps);
            let newGroup = {
              id: newGroupId,
              level: groupIndex + 1,
              firstStepNumber:
                newGroupId > 0 ? firstStep : step.process_step.Number,
              exp: maxLevel - groupIndex,
              iteration:
                step.Groups?.length > 0 &&
                step.Groups.find((f) => f.id == newGroupId)?.iteration
                  ? step.Groups.find((f) => f.id == newGroupId)?.iteration
                  : 0,
            };
            fullGroups.push(newGroup);
          }
          let orderCounter = 0;
          for (const group of fullGroups) {
            orderCounter =
              orderCounter +
              (Math.pow(10000, group.exp) * group.firstStepNumber +
                ((group.iteration + 1) *
                  (Math.pow(10000, group.exp) + step.process_step.Number)) /
                  100);
          }

          // orderCounter +=
          //   Math.pow(1000, group.exp) * group.firstStepNumber +
          //   (group.iteration * Math.pow(1000, group.exp)) / 100;

          step.Key = orderCounter;
          step.Groups = fullGroups;
          step.GroupId = step.process_step.GroupId;

          // let newStep = new TestScenarioLineStep();
          // newStep.Number = index + 1;
          // newStep.ProcessStepId = step.id;
          // newStep.TestScenarioLineId = this.newTestLine.id;
          // newStep.GroupId = step.GroupId;
          // newStep.Selected = step.Mandatory;
          // newStep.Key = orderCounter;

          // newStep.Groups = fullGroups;

          try {
            await step.save();
          } catch (error) {
            handleErrorResponse(error);
          }
        }
      }
    },
  },
  computed: {
    isPending() {
      return (
        this.isFindProcessStepPending || this.isFindTestScenarioLineStepPending
      );
    },
    testScenarioLineStepParams() {
      if (this.testScenarioLine.id) {
        return {
          query: { TestScenarioLineId: this.testScenarioLine.id },
          $sort: { Key: 1 },
        };
      } else return { query: { id: -1 } };
    },
    processStepParams() {
      return { query: { VariantId: this.variant.id }, $sort: { Number: 1 } };
    },
    groupParams() {
      return { query: { VariantId: this.variant.id } };
    },
    filteredStepsOnSelection() {
      if (this.showAll) {
        return this.testScenarioLineStep;
      } else {
        return this.testScenarioLineStep.filter((f) => f.Selected == true);
      }
    },
    lineReferences() {
      return this.testLines
        .filter(
          (f) =>
            f.ReferenceName?.length > 0 && f.Order < this.testScenarioLine.Order
        )
        ?.map((m) => {
          return { id: m.id, value: `${m.ReferenceName} (${m.Name})` };
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.testRemark {
  width: 200px;
  border: 1px solid lightgrey;
  padding: 5px;
  margin: 5px;
  min-height: 40px;
  position: relative;
}
.testRemark .icon-top-right {
  position: absolute;
  right: 0px; /* Adjust the value to fine-tune the position */
  top: 50%; /* Start at the middle of the box */
  transform: translateY(-50%); /* Adjust to perfectly center vertically */
  border-radius: 50%; /* Optional: Makes the icon background circular */
  padding: 5px; /* Optional: Adds some space around the icon */
}
span.circle {
  // background: red;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 2px;
  text-align: center;
  width: 1.6em;
}

span.pointer {
  cursor: pointer;
}
</style>
